<template>
    <custom-vue-table class="pl-3 pr-3" title="Purchase Return" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, vendor, end_date }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="30r">
                    <div class="p-3">
                        <validated-ajax-vue-select name="Vendor" :url="vendorOptions"
                                                   label="Vendor"
                                                   v-model="vendor"/>
                        <validated-input type="date" label="Start Date" name="Start Date"
                                               v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                               v-model="end_date"/>
                    </div>
                </drop-btn>
                <btn class="ml-3" @click="$router.push('/warehouse/return/add/')" size="md" text="Add"
                     icon="fa fa-plus"/>
            </div>
        </template>
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn color="primary" class="btn-basic-b" size="xs" @click="viewDetails(rowData)" text="View"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'ListingComponent',
    data () {
        return {
            loading       : false,
            start_date    : '',
            end_date      : '',
            vendor        : '',
            listUrl       : urls.warehouseAdmin.purchaseReturn.list,
            vendorOptions : urls.warehouseAdmin.vendorOptions,
            fields        : [
                {
                    name      : 'return_id',
                    sortField : 'return_id',
                    title     : 'Return ID'
                },
                {
                    name      : 'entry_id',
                    sortField : 'entry_id',
                    title     : 'Purchase ID'
                },
                {
                    name      : 'vendor.vendor_name',
                    sortField : 'vendor.vendor_name',
                    title     : 'Vendor'
                },
                {
                    name      : 'date',
                    sortField : 'date',
                    title     : 'Date'
                },
                {
                    name      : 'approved_by',
                    sortField : 'approved_by',
                    title     : 'Approved By'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    methods : {
        viewDetails (rowData) {
            this.$router.push('/warehouse/return/' + rowData.entry_id + '/details/');
        }
    }
};
</script>
